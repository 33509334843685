.help .image h4 {
  font-size: 40px;
  margin-bottom: 25px;
  margin-top: 20px;
  font-weight: 400;
}

.help .image p {
  width: 80%;
}
.help .image img {
  max-width: 200px;
  margin: 0 auto;
  display: block;
}
.help .content li {
  margin-bottom: 25px;
  display: flex;
  align-items: start;
}

.help .content li img {
  margin-bottom: 25px;
  display: flex;
  margin-right: 20px;
  align-items: start;
  width: 50px;
}

.help .content li h5 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
