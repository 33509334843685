.features-area .features-box {
  padding-left: 35px;
}

.features-area .item-grid .item {
  margin-top: 30px;
  transition: all 0.35s ease-in-out;
}
.features-area .item {
  padding: 50px 30px;
  background: var(--primary-color);
  text-align: center;
  border-radius: 8px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  color: #ffffff;
  transition: all 0.35s ease-in-out;
}

.features-area .item a,
.features-area .item p {
  color: #ffffff;
}

.features-area .item h5 {
  font-weight: 600;
}

.features-area {
  position: relative;
  background: #f8fafd;
}

@media (max-width: 768px) {
  .features-area .features-box {
    margin-top: 40px;
    padding: 0;
  }

  .features-area .item-grid:first-child {
    margin-top: 0;
  }
}
@media (max-width: 576px) {
  .features-area .features-box {
    padding: 0px 15px;
  }
}
