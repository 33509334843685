.inner-style-one.about-area {
  background: #f8fafd;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 246px 0 125px;
}
.inner-style-one .about-left {
  position: relative;
  z-index: 1;
  left: -68px;
}

.inner-style-one .abour-award-box {
  background: #af005d;
  display: flex;
  align-items: center;
  padding: 25px 35px 22px;
  width: 47%;
  border-radius: 5px;
  gap: 23px;
  position: absolute;
  top: 49px;
  right: -30px;
  animation: bounceleft 4s linear infinite;
}

.inner-style-one .about-shape {
  position: absolute;
  bottom: 11px;
  left: 152px;
  z-index: -1;
  animation: bounce 4s linear infinite;
}
.inner-style-one .about-award-content h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  margin: 0 0 4px;
  border-bottom: 1px solid #fff;
  padding-bottom: 6px;
}
.inner-style-one .about-award-content p {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  font-family: "Rajdhani", sans-serif;
  margin: 0;
}

.inner-style-one .about-conuter-box {
  background: #0d67b7;
  text-align: center;
  width: 220px;
  height: 220px;
  border-radius: 50%;
  line-height: 81px;
  padding-top: 14px;
  border: 6px solid #fff;
  position: absolute;
  bottom: 107px;
  left: 163px;
}
.inner-style-one .about-counter-content {
  margin-top: -14px;
}
.inner-style-one .about-counter-content span,
.inner-style-one .about-counter-content h4 {
  color: #fff;
  font-size: 45px;
  display: inline-block;
  font-weight: 700;
  font-family: "Rajdhani";
}
.inner-style-one .about-counter-content p {
  color: #fff;
  margin: -13px 0 0;
  line-height: 0;
}

.inner-style-one .abour-right {
  padding-left: 40px;
}
.inner-style.section-title.left {
  margin-bottom: 27px;
}
.inner-style.section-title.left h4 {
  font-size: 18px;
  color: #0c5adb;
  font-weight: 600;
  margin: 0 0 17px;
}
.inner-style.section-title.left h1 {
  font-size: 42px;
  font-weight: 700;
  line-height: 40px;
}
.inner-style.section-title.left p.section-desc {
  margin: 0;
  width: 100%;
  margin-top: 19px;
}
.inner-style-one .about-list-item ul li {
  color: #041424;
  margin: 0;
  line-height: 35px;
}
.inner-style-one .about-list-item ul li i {
  display: inline-block;
  color: #0c5adb;
  font-size: 26px;
  position: relative;
  top: 4px;
  margin-right: 12px;
}
.inner-style-one .about-text {
  display: flex;
  align-items: center;
  gap: 27px;
}
.inner-style-one .about-number h4 {
  font-size: 22px;
  font-weight: 600;
  color: #0c5adb;
  border: 2px solid #0c5adb;
  width: 65px;
  height: 65px;
  text-align: center;
  border-radius: 50%;
  line-height: 65px;
}
.inner-style-one .about-text-content h4 {
  font-size: 24px;
  margin: 0 0 0;
  font-weight: 600;
}
.inner-style-one .about-text-content p {
  font-size: 18px;
  color: #686868;
  margin: 0px 0 0;
}
.inner-style-one .about-company {
  background-color: #eff6fd;
  padding: 23px 29px 27px;
  display: flex;
  border-radius: 7px;
  margin-top: 38px;
}
.inner-style-one .about-company-icon i {
  display: inline-block;
  font-size: 40px;
  color: #0c5adb;
  margin-right: 20px;
}
.inner-style-one .about-company-desc p {
  font-size: 18px;
  font-style: italic;
  margin: 0;
}
.inner-style.toptech-button a {
  border-radius: 5px;
  margin-top: 38px;
  position: relative;
  transition: 0.5s;
  z-index: 1;
}
.inner-style.toptech-button i {
  display: inline-block;
  color: #fff;
  font-size: 24px;
  position: relative;
  top: 4px;
  left: 3px;
  transition: 0.5s;
}

/*bounce-left-right-animation*/

@keyframes bounceleft {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
  }

  50% {
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
  }

  100% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
  }
}

/*bounce-top-bottom-animation*/

@keyframes bounce {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }

  50% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }

  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }
}
