section.banner_menu {
  border-bottom: 1px solid #e6e6e6;
  padding: 0;
  position: relative;
  height: 90px;
  background-color: #f4f4f4;
  border-top: 1px solid #fff;
  overflow: hidden;
}
section.banner_menu .banner_menu_cont {
  text-align: center;
  max-width: 73.125em;
  margin-left: auto;
  margin-right: auto;
  height: 90px;
  width: auto;
  position: relative;
}
section.banner_menu .banner_menu_cont ul {
  list-style: none;
  margin: 0 auto;
  height: 90px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
}
section.banner_menu .banner_menu_cont ul li {
  display: inline-block;
  padding: 0;
  margin: 0 8px 0 8px;
  height: 90px;
}

section.banner_menu .banner_menu_cont ul li a.active {
  color: #2c84a2;
  background-color: #e7e7e7;
}

section.banner_menu .banner_menu_cont ul li a {
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
  color: #778185;
  position: relative;
  height: 42px;
  line-height: 42px;
  display: block;
  overflow: hidden;
  padding: 0 17px;
  font-weight: 600;
  margin: 24px 0 0 0;
}
