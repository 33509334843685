.staticPage {
  color: #2f2f2f;
  line-height: 23px;
  padding-bottom: 60px;
}
.contentPage ol {
  padding-left: 40px;
}
.contentPage ol li {
  list-style: decimal;
  line-height: 24px;
}
.contentPage ul {
  padding-left: 30px;
}
.contentPage ul li {
  line-height: 24px;
}
