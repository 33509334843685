.fun-factor-area .fun-fact {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fun-factor-area .fun-fact .icon i {
  background: linear-gradient(90deg, #2d76b8 0%, #e07626 75%);
  -webkit-background-clip: text;
}
.fun-factor-area .fun-fact .icon i {
  display: inline-block;
  font-size: 70px;
  margin-right: 20px;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #4154f1 0, #0e20ad 75%);
  -webkit-background-clip: text;
}
.fun-factor-area .item .timer {
  display: block;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  margin-top: -5px;
  margin-bottom: 10px;
  color: #0e2b3d;
}
.fun-factor-area .item .medium {
  display: block;
  margin-bottom: -5px;
  text-transform: capitalize;
}
.fun-factor-area .fun-fact-items:after {
  background: #fff;
}

.fun-factor-area .fun-fact-items .fun-fact .info {
  text-align: center;
}

/* .fun-factor-area .fun-fact-items:after {
  position: absolute;
  left: -100%;
  bottom: 0;
  content: "";
  height: 100%;
  width: 400%;
  background: #f3f7fd;
  z-index: -1;
} */
@media (min-width: 768px) {
  .happyClient .fun-fact-items .col-lg-3 {
    flex: 0 0 33%;
    max-width: 33%;
  }
}
