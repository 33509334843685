.ring {
  position: fixed;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  background: rgb(45 118 184 / 35%);
  border-radius: 100%;
  transform: translate(-50%, -50%);
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  will-change: width, height, transform, border;
  z-index: 999;
  pointer-events: none;
}

.dot {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: black;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: 999;
  pointer-events: none;
}
