.banner-area.auto-height,
.banner-area.auto-height div {
  height: auto;
}
.banner-area {
  position: relative;
  overflow: hidden;
}
.banner-area.auto-height .content {
  padding: 150px 0;
}
.banner-area .content h4 {
  margin-bottom: 25px;
}
.banner-area .content h2 {
  font-size: 42px;
  margin-bottom: 25px;
}
.banner-area.text-color .content h2 strong {
  font-weight: 800;
  color: #2d76b8;
}

.banner-area .content a {
  margin-top: 20px;
}
.banner-area.inc-shape .thumb {
  position: relative;
  z-index: 1;
  padding-top: 30px;
}

.banner-area.inc-shape .thumb img {
  width: 100%;
}
.banner-area.inc-shape .thumb::after {
  position: absolute;
  right: 0;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: url(../../assets/image/shape/2.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  z-index: -1;
  display: none;
}
.banner-area .thumb {
  position: relative;
  /* top: 120px; */
}
img {
  border: none;
  outline: none;
  max-width: 100%;
}

@media (max-width: 768px) {
  .banner-area .thumb {
    display: none;
  }
}
@media (max-width: 576px) {
  .banner-area.auto-height .content {
    padding: 50px 0;
    text-align: center;
  }
}
