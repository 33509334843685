.recommended {
  background: #f8fafd;
}

.recommended .single-team-box {
  margin-bottom: 30px;
  position: relative;
}
.recommended .team-thumb img {
  width: 100%;
}
.recommended .team-content {
  position: absolute;
  filter: drop-shadow(0px 10px 10px rgba(207, 218, 235, 0.2));
  background-color: #ffffff;
  text-align: center;
  padding: 24px 15px 75px;
  border-radius: 4px;
  top: 210px;
  /* clip-path: polygon(0 0, 100% 0%, 100% 100%, 50% 78%, 0 100%);
  transition: 0.5s;
  height: 233px; */
}
.recommended .team-content h4 {
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 4px;
  transition: 0.5s;
}
.recommended .team-content p {
  margin: 2px 0 0;
}
.recommended .team-shape {
  position: absolute;
  bottom: -54px;
  left: -10px;
}
.recommended .team-socila-icon-box {
  position: absolute;
  left: 125px;
  bottom: -21px;
}
.recommended .team-share {
  display: inline-block;
  font-size: 14px;
  color: #fff;
  background: var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
  transition: 0.5s;
  z-index: 999;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  line-height: 45px;
  text-align: center;
}
.recommended .team-social-icon {
  position: relative;
  top: -81px;
  left: -27px;
}
.recommended .team-social-icon ul li {
  list-style: none;
  margin-bottom: 5px;
  display: inline-block;
}
.recommended .team-social-icon ul li:nth-child(1) a {
  transition-delay: 0.5s;
}
.recommended .team-social-icon ul li:nth-child(2) a {
  transition-delay: 0.4s;
}
.recommended .team-social-icon ul li:nth-child(3) a {
  transition-delay: 0.2s;
}
.recommended .team-social-icon ul li:nth-child(4) a {
  transition-delay: 0s;
}
.recommended .team-social-icon ul li a {
  display: inline-block;
  color: #fff;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  position: relative;
  font-size: 16px;
  margin: 0 5px;
}

/* .recommended .single-team-box:hover .team-content {
  background-color: var(--primary-color);
  margin-top: -55px;
  height: 193px;
} */
.recommended .single-team-box:hover .team-content p,
.recommended .single-team-box:hover .team-content h4 {
  color: #fff;
}
.recommended .single-team-box:hover .team-content {
  background-color: var(--primary-color);
  height: 100%;
  top: 0;
  transition: all 0.5s ease;
}
.recommended .single-team-box {
  height: 372px;
  overflow: hidden;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.recommended .single-team-box button.btn.btn-pink {
  position: relative;
  z-index: 9999;
  background: #d80e78;
  color: #fff;
  margin-top: 27px;
  border: 1px solid #d80e78;
}

.recommended .single-team-box button.btn.btn-pink:hover {
  color: #d80e78;
  background: transparent;
}
