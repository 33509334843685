.work-process-area .site-heading h4 {
  font-weight: 600;
  text-transform: uppercase;
  color: #2d76b8;
}

.work-process-area .site-heading h2 {
  font-weight: 600;
  padding-bottom: 20px;
  margin-bottom: 0;
  position: relative;
  letter-spacing: 1px;
  line-height: 1.4;
}

.site-heading h2:after,
.site-heading h2:before {
  left: 50%;
  background: #086ad8;
  height: 2px;
  position: absolute;
}

.site-heading h2:before {
  background: #2d76b8 none repeat scroll 0 0;
}
.site-heading h2:before {
  bottom: 0;
  content: "";
  margin-left: -25px;
  width: 40px;
}

.site-heading h2:after {
  background: #2d76b8 none repeat scroll 0 0;
}
.site-heading h2:after {
  bottom: 0;
  content: "";
  margin-left: 20px;
  width: 5px;
}
