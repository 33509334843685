header {
  /* padding: 30px 35px; */
  box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.04);
}

header .navbar-collapse {
  justify-content: center;
}

header .navbar-brand {
  max-width: 100px;
}

header .navbar_link {
  font-family: "Proxima";
  font-size: 16px;
  font-weight: 600;
  line-height: 26.07px;
  color: #333;
  padding: 40px 15px;
  transition: 0.35s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header .sub_first .navbar_link,
header .sub_sec .navbar_link {
  margin: 0;

  padding: 0;
}

header .navbar_link i {
  padding-left: 5px;
  /* transition: 0.35s ease-in-out; */
}

header .active {
  color: #2d76b8;
}

header .nav-item {
  position: relative;
  transition: 0.4s all;
}
header .nav-item:hover .main_dropdown {
  display: block;
}

header .nav-item:hover .sub_first {
  opacity: 1;
}

header .nav-item .sub_first .nav-item:hover .sub_sec {
  display: block;
}

header .dropdown .navbar_link {
  align-items: center;
  justify-content: flex-start;
}

header .dropdown .navbar_link i {
  font-size: 30px;
  margin-right: 15px;
}

header .dropdown .navbar_link h6 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.39;
  letter-spacing: 0.29px;
  color: #333;
  margin-bottom: 0;
  transition: 0.3s;
}

header .dropdown .navbar_link p {
  font-size: 13px;
  line-height: 1.39;
  letter-spacing: 0.29px;
  color: #333;
  margin-bottom: 0;
}

header .nav-item .dropdown-menu {
  background: #fff;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.15);
  opacity: 0;
  margin: 0;
  position: absolute;
  /* bottom: 13px; */
  left: -180px;
  top: 106px;
  display: flex;
  flex-wrap: wrap;
  padding: 25px 40px;
  transition: 0.3s ease-in-out;
  width: 750px;
}

header .nav-item .main_dropdown {
  display: none;
}

header .nav-item .dropdown-item {
  /* border-bottom: 1px solid #f5f5f5; */
  width: 50%;
  padding: 0;
  color: #333;
  font-weight: 600;
  text-transform: capitalize;
  display: block;
  font-size: 16px;
  margin: 0;
  margin-bottom: 25px;
  position: relative;
}
header .nav-item .dropdown-item:hover {
  background-color: unset;
}

header .nav-item .dropdown-item:hover > a h6 {
  color: #2d76b8;
}

header .nav-item ul .dropdown .dropdown-menu {
  position: absolute;
  top: 0px;
  left: 250px;
}

/* header .nav-item ul .dropdown:hover .dropdown-menu {
  display: block;
} */

header .navbar_link:hover {
  color: #2d76b8;
  background-color: unset;
}

header .navbar_link:hover .fa-angle-down {
  transform: rotate(180deg);
  padding-left: 0;
  padding-right: 5px;
}

header .call_box {
  display: flex;
  align-items: center;
  /* padding: 25px 0; */
}

header .call_box .contact i {
  font-size: 40px;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid #e7e7e7;
  color: #2d76b8;
}

header .call_box .contact p {
  margin: 0;
  font-size: 15px;
  line-height: 25px;
  color: #232323;
}
header .call_box .contact p strong {
  display: block;
  color: #0e2b3d;
  font-weight: 700;
}

@media (max-width: 768px) {
  header .navbar-toggler {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 25px 0;
  }
  .call_box {
    display: none;
  }

  .navbar-nav {
    margin-top: 20px;
  }

  /* header .navbar_link {
    padding: 10px 0px;
    border-bottom: 1px solid #e0e0e0;
    font-weight: 600;
  } */

  
}

/* pricing_sec */

.pricing_sec .logo_text {
  max-width: 110px;
}
.pricing_modal .pricing_sec {
  padding: 20px 40px;
}
.pricing_modal .pricing_sec h4 {
  font-weight: 700;
  color: rgb(39, 43, 49);
  font-size: 2rem;
  margin-bottom: 0;
}

.pricing_modal .pricing_sec p {
  font-size: 1rem;
}

.pricing_modal .pricing_sec p span {
  background-color: rgb(189, 237, 178);
  color: rgb(9, 140, 0);
  padding: 0.4rem;
  font-weight: 700;
  border-radius: 6px;
}
.bg_popup {
  background-size: cover;
  padding: 0;
  background-position: center;
}
.pricing_modal .modal-content {
  padding: 0 !important;
}
.pricing_sec .form-control {
  border-radius: 6px;
  background-color: rgb(247, 248, 250);
  color: rgb(71, 88, 121);
  font-size: 14px;
  border: 1px solid #e5e5ea;
}
p.form_links a {
  color: #0d68b7;
}
.pricing_modal .pricing_sec p.error {
  color: red;
}
.top-bar-area.inc-pad {
  overflow: hidden;
}
@media (max-width: 1170px) {
  .pricing_modal .pricing_sec {
    padding: 20px 15px;
  }
}
@media (max-width: 991px) {
  .pricing_modal .bg_popup {
    display: none;
  }
  .pricing_modal .pricing_sec h4 {
    font-size: 20px;
  }
  .pricing_modal .modal-content {
    max-width: 95%;
  }

  nav.navbar .navbar-toggler {
    order: 9;
  }

  nav.navbar {
    display: flex;
    flex-direction: row;
  }
  .container {
    max-width: 100%;
  }
   header .nav-item .sub_first {
   
    left: 0;
    width: 100%;
    position: unset;     
     padding: 10px;
}
    header .navbar_link {
        padding: 10px 0px;
        border-bottom: 1px solid #e0e0e0;
        font-weight: 600;
    }
    .navbar-nav li:last-child a:last-child {
    border: none;
  }
}

@media (max-width: 767px) {

 header .nav-item .sub_first {
    display: block;
 }
.inner-style .choose-us-thumb img {
   
    left: 0 !important;
}
header .nav-item .dropdown-item{
  width: 100%;
}
header .dropdown .navbar_link p {
   
    white-space: break-spaces;
}
}
