* {
  margin: 0;
  margin-bottom: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
  font-size: 16px;
  color: #333;
  /* background: rgba(4, 1, 28, 1); */

  /* line-height: 30px; */
  position: relative;
}

a {
  text-decoration: none;
  color: #333;
}

p {
  margin: 0 0 15px;
  text-transform: none;
  line-height: 26px;
  color: #666;
}

span.highlight {
  font-weight: 700;
  /* color: #000; */
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
ol,
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-family: "Proxima";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #0e2b3d;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0;
  margin: 0 0 15px;
}

p {
  font-weight: 400;
  font-size: 14px;
}

.text-light {
  color: #f8f9fa !important;
}

.bg_dark {
  background: #f8fafd;
}
.default_padding {
  padding-top: 80px;
  padding-bottom: 80px;
}

input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.mb_30 {
  margin-bottom: 30px;
}

.mb_50 {
  margin-bottom: 50px;
}

.mt_50 {
  margin-top: 50px;
}

.box_bg {
  background: rgba(255, 255, 255, 0.1);
}

/*------heading-------*/

.section_heading {
  max-width: 800px;
  /* display: flex; */
  text-align: center;
  margin: 0 auto;
}

.section_heading h2 {
  font-weight: 600;
  text-transform: uppercase;
  color: #2d76b8;
}

.section_heading h4 {
  font-weight: 400;
  padding-bottom: 20px;
  margin-bottom: 0;
  position: relative;
  letter-spacing: 1px;
  line-height: 1.4;
  font-size: 18px;
}

.sub_heading {
  font-family: "Proxima";
  font-size: 40px;
  font-weight: 400;
  line-height: 49.57px;
}

.main_heading {
  font-family: "Proxima";

  font-size: 48px;
  font-weight: 400;
  line-height: 56.88px;
}

.image_heading {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*-------paragraph--------*/
.main_text {
  font-family: "Proxima";
  font-size: 25px;
  font-weight: 400;
  line-height: 37.5px;
}

.main_text {
  font-family: "Proxima";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

.underLine {
  font-weight: 700;
  color: rgba(233, 87, 192, 1);
  /* border-bottom: 1px solid rgba(233, 87, 192, 1); */
  position: relative;
  cursor: pointer;
}

.underLine::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background: rgba(233, 87, 192, 1);
  left: 0;
  bottom: 5px;
}

/*--------button----------------------*/

.btn_md {
  padding: 15px 40px;
}

.btn_primary:hover {
  background: transparent;
  color: var(--primary-color);
}

.btn_primary,
.btn_secondary {
  padding: 8px 20px;
  color: #fff;
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  display: inline-block;
  position: relative;
  z-index: 1;
  transition: 0.5s;
  text-transform: capitalize;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  transition: 0.5s;
}
.btn_secondary {
  background: transparent;
  color: var(--primary-color);
}

.btn_secondary:hover {
  background: var(--primary-color);
  color: #fff;
}

.btn i {
  font-size: 20px;
  margin-left: 5px;
  position: relative;
  top: 3px;
}
/* img {
  width: 100%;
} */
@media (max-width: 767px) {
  .default_padding {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.rowReverse .row {
  flex-direction: row-reverse;
}
.staticPage h3 {
  font-size: 23px;
  margin-top: 20px;
}
.why_choose_us .why_choose .why_choose_icon img {
  width: 100%;
  min-width: 70px;
}
/*  */


.callBack  .modal-dialog .modal-content{
    border-radius: 30px;
    padding: 0 !important;
}
.callBack  .right_img {
    margin-top: -50px;
}
.callBack  .header_content {
    text-align: center;
    padding: 0 50px;
}
.callBack .modal-dialog .modal-content {
    border-radius: 30px;
    display: block !important;
    padding: 10px 10px !important;
    background: #fff;
    border-radius: 30px;
}

.callBack  .header_content h4 {
    font-size: 70px;
    color: #fff;
    font-weight: 800;
    line-height: 70px;
}

.callBack  .header_content p {
    font-size: 20px;
    color: #fff;
    margin-bottom: 30px;
}
.callBack  .header_content button {
    background: #d70d78;
    color: #fff;
    padding: 10px 20px;
    border: 1px solid;
    border-radius: 24px;
}

.callBack  .popup_inner ul {
    display: flex;
    justify-content: space-between;
}

.callBack  .popup_inner {
    padding: 15px 40px;
}

.callBack  .popup_inner ul li {
    width: 50%;
    position: relative;
}

.callBack  .popup_inner ul li h5 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 6px;
}

.callBack  .popup_inner ul li p {
    font-size: 20px;
    color: #000;
}

.callBack .popup_inner ul li:last-child::before {content: "";left: 0;top: 0;width: 2px;height: 100%;background: #d4d9e2;position:absolute;}

.callBack .popup_inner ul li:last-child {
    padding-left: 30px;
}