.works-about-area {
  position: relative;
  z-index: 1;
  background: #f3f7fd;
}
.works-about-items {
  position: relative;
  z-index: 1;
  /* padding-bottom: 20px; */
}
.works-about-items::before {
  position: absolute;
  right: 20%;
  top: 0;
  content: "";
  height: 100%;
  width: 500%;
  background: #f3f7fd;
  z-index: -1;
}
.works-about-area .works-about-items .info {
  padding-right: 80px;
}

.works-about-items .info > h5 {
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 25px;
  display: inline-block;
  position: relative;
  z-index: 1;
  padding-left: 45px;
  color: #2d76b8;
}
.works-about-items .info > h5::after {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  height: 2px;
  width: 30px;
  background: #2d76b8;
  margin-top: -1px;
}

.works-about-items .info h2 {
  font-weight: 600;
  margin-bottom: 25px;
}

.works-about-items ul li {
  margin-top: 25px;
  align-items: center;
  position: relative;
  z-index: 1;
  padding-left: 25px;
}
.works-about-items ul li h5 {
  text-transform: capitalize;
  font-weight: 600;
  margin: 0;
  font-size: 16px;
}

.works-about-area .works-about-items p a {
  font-weight: 600;
}

.works-about-items ul li:after {
  position: absolute;
  left: 0;
  top: -4px;
  content: "\f058";
  height: 100%;
  width: 100%;
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  color: #086ad8;
}

.works-about-area .works-about-items .info a {
  margin-top: 30px;
  /* padding: 15px 40px; */
}
.works-about-area .works-about-items .info .btn {
  padding: 15px 40px;
}
.works-about-area .works-about-items .thumb {
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
}
.works-about-area .works-about-items .thumb .fun-fact {
  position: absolute;
  left: -50px;
  bottom: -50px;
  background: #ffffff;
  padding: 50px;
  box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.57);
  text-align: center;
}
.works-about-area .works-about-items .thumb .fun-fact .timer {
  display: inline-block;
  font-weight: 800;
  color: #0e2b3d;
  font-size: 2rem;
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
  margin-left: -20px;
}

.works-about-area .works-about-items .thumb .fun-fact .medium {
  display: block;
  font-weight: 600;
}

@media (max-width: 768px) {
  .works-about-area .works-about-items .info {
    padding: 0;
    margin-bottom: 30px;
  }

  .works-about-area .works-about-items .thumb .fun-fact {
    right: 50px;
    left: unset;
    padding: 50px 100px;
  }
}

@media (max-width: 576px) {
  .works-about-area .works-about-items .info {
    padding: 0 15px;
  }
}
