.main_footer {
  background: #0d67b7;
}
footer .footer_about p {
  font-size: 20px;
  color: #c2d1e2;
  margin-top: 20px;
}

.footer_about img {
  width: 150px;
}

footer .footer_link h4,
.footer_address h4 {
  font-weight: 600;
  margin-bottom: 30px;
  color: #fff;
}

footer .footer_link a,
.footer_address p {
  font-weight: 400;
  font-size: 14px;
  color: #c2d1e2;
  margin-bottom: 10px;
  transition: 0.35s all;
  display: block;
}

footer .footer_link a:hover {
  color: #fff;
}

.footer_bottom {
  background: #0f2a57;
  padding: 20px 0;
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer_bottom p {
  margin: 0;
  color: #ccc;
}

.footer_bottom ul {
  display: flex;
  align-items: center;
  justify-content: end;
}

.footer_bottom a {
  color: #c2d1e2;
  margin-left: 10px;
  border-bottom: 1px solid;
}

@media (max-width: 768px) {
}
