/* Service page css start*/

.Service_banner_area .content h2 {
  font-size: 60px;
  margin-bottom: 25px;
}
.Service_banner_area .content h2 strong {
  font-weight: 800;
  display: block;
}
.Service_banner_area .content .bottom a {
  margin-right: 30px;
}
.Service_banner_area .content .bottom .call-us {
  margin-top: 20px;
  display: flex;
  align-items: center;
  text-align: left;
}
.Service_banner_area .content .bottom .call-us i {
  background: #2d76b8;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  color: #fff;
  margin-right: 15px;
}
.Service_banner_area .content .bottom .call-us h5 {
  font-weight: 700;
  margin-bottom: 5px;
  color: #2d76b8;
}
.Service_banner_area .content .bottom .call-us span {
  font-weight: 600;
  color: #232323;
}
.service_inner .right_content i {
  color: #e2690e;
}
.faq_area .info h2 {
  font-weight: 600;
  margin-bottom: 25px;
}
.faq_area h2.accordion-header button {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  padding: 0 50px 0 0;
  cursor: pointer;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  color: #0e2b3d;
  font-weight: 800 !important;
}

.faq_area .faq-content .accordion-item {
  border: 0;
}

.faq_area .accordion {
  margin-top: 40px;
}
.faq_area .accordion-body {
  padding: 0;
  margin-bottom: 40px;
  color: #666;
  font-weight: 400;
  font-size: 14px;
}
.faq_area .appoinment-form {
  background: linear-gradient(90deg, #2d76b8 0%, #e07626 100%);
  padding: 50px;
  position: relative;
  border-radius: 8px;
  text-align: center;
  z-index: 1;
}
.faq_area .appoinment-form:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: url(../../assets/image/shape/17.webp) 0 0 / cover no-repeat;
  opacity: 0.15;
}

.faq_area .appoinment-form input.form-control,
.faq_area .appoinment-form select,
.faq_area .appoinment-form textarea {
  background: rgba(255, 255, 255, 0.1);
  border: 1px dashed rgba(255, 255, 255, 0.5);
  color: #fff;
  border-radius: 5px;
}
.faq_area .appoinment-form .form-check-input[type="checkbox"] {
  border: 1px dashed rgba(255, 255, 255, 0.5);
}
.faq_area .appoinment-form form {
  position: relative;
  z-index: 999;
}
.faq_area .appoinment-form form label {
  text-align: left;
  color: #fff;
  float: left;
}

.faq_area .appoinment-form .form-check label a {
  color: #fff;
}

.faq_area .appoinment-form form button.btn.btn-primary {
  border: 1px dashed rgba(255, 255, 255, 0.5);
  color: #fff;
  border-radius: 5px;
  background: transparent;
  margin-top: 20px;
  width: 100%;
  font-size: 20px;
}
/* Service page css end*/
