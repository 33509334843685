/* top bar start */
.top-bar-area {
  position: relative;
  background: var(--primary-color);
}

.top-bar-area::after {
  position: absolute;
  content: "";
  width: 53%;
  height: 100%;
  right: -60px;
  top: 0;
  background: #d70d78;
  z-index: 0;
  transform: skew(23deg);
}

.top-bar-area.bg-theme {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.top-bar-area.inc-pad {
  padding: 15px 0;
}
.top-bar-area li:first-child {
  margin: 0;
  padding: 0;
  border: none;
}

.top-bar-area li {
  display: inline-block;
  margin-left: 15px;
  padding-left: 15px;
  position: relative;
  z-index: 1;
  font-size: 14px;
}
.top-bar-area.bg-dark .info li i,
.top-bar-area.bg-theme .info li i {
  color: #ffffff;
}

.top-bar-area .social ul {
  margin-left: 30px;
}

.top-bar-area .social ul li a {
  color: #fff;
}
.top-bar-area .info li i {
  margin-right: 5px;
}
.top-bar-area .info-2 {
  display: flex;
  align-items: center;
  justify-content: end;
}
/* top bar end */

@media (max-width: 768px) {
  .top-bar-area .info {
    text-align: center;
  }
  .top-bar-area .info-2 {
    margin-top: 10px;
    justify-content: center;
  }
}
@media (max-width: 576px) {
  .top-bar-area {
    display: none;
  }
}
